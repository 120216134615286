import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Stack,
} from "@mui/material";
import InputField from "../components/InputField/InputField";
import { styled } from "@mui/system";
import AppBarComponent from "../components/StaticPages/AppBarComponent";
import FooterComponent from "../components/StaticPages/FooterComponent";

const Container = styled(Box)(({ theme }) => ({
  padding: "5% 2%",
  maxWidth: "70%",
  marginTop: "10%",
  textAlign: "left",
  "@media (max-width:1200px)": {
    padding: "5% 2%",
    marginTop: "20%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "30%",
  },
}));

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data: ", formData);
  };

  return (
    <>
      <AppBarComponent title='Contact Us' />
      <Container>
        <Box sx={{ mt: 7, mb: 7 }}>
          <Typography
            variant='h4'
            sx={{ fontWeight: "bold", color: "#02434A", mb: 2 }}
          >
            Get In Touch
          </Typography>
          <Typography variant='body1' sx={{ color: "#242424", mb: 2 }}>
            Send our team a quick message and we’ll get back to you as soon as
            possible.
          </Typography>
          <Typography variant='body1' sx={{ color: "#242424", mb: 4 }}>
            We’re pretty responsive and will try to respond in a few hours
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction='column'>
                  <label style={{ color: "#02434A" }} htmlFor='name'>
                    Name
                  </label>
                  <InputField
                    type='text'
                    id='name'
                    name='name'
                    width='90%'
                    value={formData.name}
                    handleInputChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Stack direction='column'>
                  <label style={{ color: "#02434A" }} htmlFor='email'>
                    Email
                  </label>
                  <InputField
                    type='email'
                    id='email'
                    name='email'
                    width='90%'
                    value={formData.email}
                    handleInputChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <label style={{ color: "#02434A" }} htmlFor='email'>
                  Message
                </label>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  id='message'
                  name='message'
                  value={formData.message}
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#02434A",
                      },
                      "&:hover fieldset": {
                        borderColor: "#02434A",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#02434A",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type='submit'
                  fullWidth
                  sx={{
                    mt: 3,
                    backgroundColor: "#E0FFF8",
                    color: "#02434A",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#93FFE6",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <FooterComponent />
    </>
  );
};

export default ContactPage;
