import React from "react";
import "./InputField.css";

const InputField = ({
  value,
  handleInputChange,
  placeholder,
  type,
  id,
  name,
  required,
  width
}) => {
  return (
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
      required={required}
      className="input"
      style={{width: width}}
    />
  );
};

export default InputField;