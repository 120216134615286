import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AppBarComponent from "../components/StaticPages/AppBarComponent";
import FooterComponent from "../components/StaticPages/FooterComponent";

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(30),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(30),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(20),
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 32,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

const PrivacyPolicy = () => {
  return (
    <>
      <AppBarComponent title='Privacy Policy' />
      <Container>
        <TitleTypography>Privacy Policy</TitleTypography>
        <BodyTypography>
          This Privacy Policy describes the privacy practices of Itini corp.
          (“Itini” “we” “us” or “our”) and how we handle personal information
          that we collect through our mobile applications, our website located
          at https://itini.co (the “Site”) and through any other applications,
          websites, or services that we own or control and that link to this
          Privacy Policy (collectively, the “Service”).
        </BodyTypography>
        <BodyTypography>
          The Service is designed to be an AI-powered platform for vacation and
          travel planning, recommendations, and booking. The Service enables
          users to create and share travel itineraries and plans as well as
          browse and book flights, lodgings, vacation packages, and other
          travel-related products and services.
        </BodyTypography>
        <BodyTypography>
          We care about the protection and confidentiality of your personal
          information and have developed this Privacy Policy to describe the
          information we collect through the Service and to explain how it is
          used and to whom it may be disclosed.
        </BodyTypography>
        <TitleTypography mt={5} mb={5} fontWeight='bold'>
          Personal Information We Collect
        </TitleTypography>
        <BodyTypography fontWeight='bold'>
          Information you provide to us:
        </BodyTypography>
        <BodyTypography>
          ● Contact and account information such as your first and last name,
          email address, phone number, and password. If you create an account or
          log into the Service using third-party services such as Google, we
          will receive personal information about you (such as your name, email
          address, and/or phone number) as permitted by your profile settings on
          the third-party service in order to authenticate you.
        </BodyTypography>
        <BodyTypography>
          ● Travel program memberships. You will be invited to store information
          about your frequent flyer accounts, hotel loyalty program memberships,
          and similar travel programs with our Service.
        </BodyTypography>
        <BodyTypography>
          ● Information about your reservations for flights, lodgings, vacation
          packages, and other travel-related products and services, and related
          itineraries.
        </BodyTypography>
        <BodyTypography>
          ● Payment information needed to complete your transactions with us
          including name, payment card information, and billing information.
          This information is processed by third-party payment processors.
        </BodyTypography>
        <BodyTypography>
          ● Content and metadata of any messages that you send using the Service
          or to us directly.
        </BodyTypography>
        <BodyTypography>
          ● Communications that we exchange with you including when you respond
          to our surveys or contact us with questions, feedback, or otherwise.
        </BodyTypography>
        <BodyTypography>
          ● Interests and preferences. In creating your account, you will have
          the option to share information about your travel preferences so that
          we may offer you a more customized experienced.
        </BodyTypography>
        <BodyTypography>
          ● Other data not specifically listed here which we will use as
          described in this Privacy Policy or as otherwise disclosed at the time
          of collection.
        </BodyTypography>
        <BodyTypography mt={5} mb={5}>
          Automatic data collection. We and our service providers may
          automatically log and combine information about you, your computer or
          mobile device, and your interaction over time with the Service, online
          resources, and our communications, such as:
        </BodyTypography>
        <BodyTypography>
          ● Device data such as your computer’s or mobile device’s operating
          system type and version, manufacturer and model, browser type, screen
          resolution, device type (e.g. phone, tablet), IP address, unique
          identifiers (including identifiers used for advertising purposes),
          language settings, and general location information such as city,
          state or geographic area.
        </BodyTypography>
        <BodyTypography>
          ● Online activity data such as pages or screens you viewed, how long
          you spent on a page or screen, the website you visited before browsing
          to the Service, navigation paths between pages or screens, information
          about your activity on a page or screen, access times, duration of
          access, and whether you have opened or otherwise engaged with our
          communications.
        </BodyTypography>
        <BodyTypography mt={5} mb={5}>
          We use the following tools for automatic data collection:
        </BodyTypography>
        <BodyTypography>
          ● Cookies, which are text files that websites store on a visitor’s
          device to uniquely identify the visitor’s browser or to store
          information or settings in the browser for the purpose of helping you
          navigate between pages efficiently, remembering your preferences,
          enabling functionality, and helping us understand user activity and
          patterns.
        </BodyTypography>
        <BodyTypography>
          ● Local storage technologies, like HTML5, that provide
          cookie-equivalent functionality but can store larger amounts of data,
          including on your device outside of your browser in connection with
          specific applications.
        </BodyTypography>
        <BodyTypography>
          ● Web beacons, also known as pixel tags or clear GIFs, which are used
          to demonstrate that a webpage or email was accessed or opened, or that
          certain content was viewed or clicked.
        </BodyTypography>
        <TitleTypography mt={5}>
          How We Use Personal Information
        </TitleTypography>
        <BodyTypography>
          We use personal information for the following purposes or as otherwise
          described at the time of collection:
        </BodyTypography>
        <BodyTypography>
          Provide our Service: We use personal information to operate, maintain,
          and provide you with our Service including to facilitate your
          bookings.
        </BodyTypography>
        <BodyTypography>
          Communicate with you about our Service: We use personal information to
          respond to your requests, provide user support, communicate with you
          about our Service including by sending announcements, surveys,
          reminders, updates, security alerts, and support and administrative
          messages.
        </BodyTypography>
        <BodyTypography>
          Improve, monitor, personalize, and protect our Service: We use your
          personal information, including sensitive information, to improve and
          keep our Service safe for our users.
        </BodyTypography>
        <BodyTypography>
          ● Ensuring you’re over 18 years to use the Service.
        </BodyTypography>
        <BodyTypography>
          ● Understanding your needs and interests, and personalizing your
          experience with the Service and our communications.
        </BodyTypography>
        <BodyTypography>
          ● Improving and adding features to our services.
        </BodyTypography>
        <BodyTypography>
          ● Troubleshooting, testing and research, and keeping the Service
          secure.
        </BodyTypography>
        <BodyTypography>
          ● To ensure our training models are based on data that is
          representative of the population and to mitigate bias in the AI.
        </BodyTypography>
        <BodyTypography>
          Research and development. We may use personal information for research
          and development purposes, including to analyze trends, usage,
          activities and statistics, and to improve the Service and our
          business. As part of these activities, we may create or use
          aggregated, de-identified or other anonymized data from personal
          information we collect.
        </BodyTypography>
        <BodyTypography>
          Compliance and protection. We may use personal information to comply
          with legal obligations, and to defend us against legal claims or
          disputes, including to:
        </BodyTypography>
        <BodyTypography>
          ● Protect our, your or others’ rights, privacy, safety or property
          (including by making and defending legal claims)
        </BodyTypography>
        <BodyTypography>
          ● Audit our internal processes for compliance with legal and
          contractual requirements and internal policies.
        </BodyTypography>
        <BodyTypography>
          ● Enforce the terms and conditions that govern the Service.
        </BodyTypography>
        <BodyTypography>
          ● Prevent, identify, investigate and deter fraudulent, harmful,
          unauthorized, unethical or illegal activity, including cyberattacks
          and identity theft.
        </BodyTypography>
        <BodyTypography>
          ● Comply with applicable laws, lawful requests and legal process, such
          as to respond to subpoenas or requests from government authorities.
        </BodyTypography>
        <TitleTypography mt={5}>
          How We Disclose Personal Information
        </TitleTypography>
        <BodyTypography>
          We may disclose your personal information to third parties as
          described below:
        </BodyTypography>
        <BodyTypography>
          ● Service providers. We may rely on third-party service providers in
          furtherance of the purposes described above. In these cases, personal
          information may be accessed by these third parties and processed or
          stored on our behalf. These service providers can include hosting
          services, cloud computing and storage services, maintenance services,
          security services, and user support services. To enable us to provide
          you with relevant responses, we will also disclose some of your
          information to AI technology providers that power our Service. For
          example, we will disclose conversations and chat history to AI
          technology providers to generate intelligent and personalized
          responses in conversations with users. We require our AI service
          providers to use your information only for the purpose of generating
          responses to your chats, and we do not allow those providers to train
          their AI models using your data.
        </BodyTypography>
        <BodyTypography>
          ● Travel partners. When you request to make a reservation for flights,
          lodgings, vacation packages and other travel-related products and
          services, we may disclose personal information to third-party travel
          partners who provide such products and services to facilitate your
          reservations.
        </BodyTypography>
        <BodyTypography>
          ● Professional advisors. We may disclose personal information to
          professional advisors, such as lawyers, auditors, bankers, and
          insurers, where necessary in the course of the professional services
          that they render to us.
        </BodyTypography>
        <BodyTypography>
          ● Authorities and others: Law enforcement, government authorities, and
          private parties as we believe in good faith to be necessary or
          appropriate for the compliance and protection purposes described
          above.
        </BodyTypography>
        <BodyTypography>
          ● Business transferees. Acquirers and other relevant participants in
          business transactions (or negotiations for such transactions)
          involving a corporate divestiture, merger, consolidation, acquisition,
          reorganization, sale or other disposition of all or any portion of the
          business or assets of, or equity interests in, Itini (including, in
          connection with a bankruptcy or similar proceedings).
        </BodyTypography>
        <TitleTypography mt={5}>
          How You Disclose Personal Information:
        </TitleTypography>
        <BodyTypography>
          ● Other users. When you establish an account on Itini, you disclose
          certain of your information provided on the Service to the Itini
          community. This information may include your profile information,
          interests, itineraries, reviews and other information or content you
          may elect to share in accordance with the privacy settings you
          establish on the Service.
        </BodyTypography>
        <TitleTypography mt={5}>Privacy Choices</TitleTypography>
        <BodyTypography>
          Delete your account: You may request that we delete your account by
          contacting us as provided in the “How to Contact Us” section below.
        </BodyTypography>
        <BodyTypography>
          Personal information requests. Where applicable, you may request the
          following in relation to personal information:
        </BodyTypography>
        <BodyTypography>
          ● Information about how we have collected and used personal
          information. We have made this information available to you by
          including it in this Privacy Policy.
        </BodyTypography>
        <BodyTypography>
          ● Access to a copy of the personal information that we have collected
          about you.
        </BodyTypography>
        <BodyTypography>
          ● Correction of personal information that is inaccurate or out of
          date.
        </BodyTypography>
        <BodyTypography>
          ● Deletion of personal information that we no longer need to provide
          the Service or for other lawful purposes.
        </BodyTypography>
        <BodyTypography>
          To make a request, please email us or write to us as provided in the
          “How to Contact Us” section below. We may ask for specific information
          from you to help us confirm your identity. Depending on where you
          reside, you may be entitled to empower an authorized agent to submit
          requests on your behalf. We will require authorized agents to confirm
          their identity and authority, in accordance with applicable laws. You
          are entitled to exercise the rights described above free from
          discrimination.
        </BodyTypography>
        <BodyTypography>
          Opt-out of marketing communications. You may opt out of
          marketing-related emails by following the opt-out or unsubscribe
          instructions in the emails you receive from us. You may also opt out
          of receiving marketing communications from us by contacting us as
          provided in the “How to Contact Us” section below. You may continue to
          receive Service-related and other non-marketing communications.
        </BodyTypography>
        <BodyTypography>
          Third-party opt-out. There are a number of ways to limit online
          tracking, which we have summarized below. Please note that these
          opt-out tools are not associated with Itini and we cannot guarantee
          that these tools work as their providers advertise them:
        </BodyTypography>
        <BodyTypography>
          ● Blocking cookies in your browser. Most browsers let you remove or
          reject cookies. To do this, follow the instructions in your browser
          settings. Many browsers accept cookies by default until you change
          your settings. For more information about cookies, including how to
          see what cookies have been set on your device and how to manage and
          delete them, visit https://www.allaboutcookies.org/.
        </BodyTypography>
        <BodyTypography>
          ● Using privacy plug-ins or browsers. You can block our websites from
          setting cookies by using a browser with privacy features, like Brave,
          or installing browser plugins like Privacy Badger, Ghostery, or uBlock
          Origin, and configuring them to block third party cookies/trackers.
          You can also opt out of Google Analytics by downloading and installing
          the browser plug-in available
          at:https://tools.google.com/dlpage/gaoptout.
        </BodyTypography>
        <BodyTypography>
          Note that because these opt-out mechanisms are specific to the device
          or browser on which they are exercised, you will need to opt out on
          every browser and device that you use.
        </BodyTypography>
        <BodyTypography>
          Do Not Track. Some Internet browsers may be configured to send “Do Not
          Track” signals to the online services that you visit. We currently do
          not respond to “Do Not Track” or similar signals. To find out more
          about “Do Not Track,” please visithttp://www.allaboutdnt.com.
        </BodyTypography>
        <TitleTypography mt={5}>Security</TitleTypography>
        <BodyTypography>
          We employ a number of technical, organizational, and physical
          safeguards designed to protect the personal information we collect.
          However, no security measures are failsafe and we cannot guarantee the
          security of your personal information.
        </BodyTypography>
        <TitleTypography mt={5}>Job Applicants</TitleTypography>
        <BodyTypography>
        When you apply for one of our open positions, we collect the information that you provide in connection with your job application. This includes but is not limited to business and personal contact information, professional credentials and skills, educational and work history, and other information that may be included in a resume or that you may provide during the interview process. This may also include demographic or diversity information that you voluntarily provide. We may also conduct background checks and receive related information. We also collect personal information from other sources where relevant for your application, such as employment research firms, recruiters, identity verification services, and information that you make publicly available on websites or social media platforms (for example, LinkedIn). Throughout the recruitment process, we may supplement your personal information in connection with the assessment of your application. For example, we may record the views of those considering your application about your suitability for the role for which you have applied and retain interview notes. If you accept an offer from us, your personal information will be incorporated into and used as part of your employee record.
        </BodyTypography>
        <BodyTypography>We use applicants’ information to facilitate our recruitment activities and process employment applications, including evaluating candidates and monitoring recruitment statistics. We also use successful applicants’ information to administer the employment relationship. We may also use and disclose applicants’ information to improve our Service and for the compliance and protection purposes described above.</BodyTypography>
        <TitleTypography mt={5}>
          Other Sites and Services
        </TitleTypography>
        <BodyTypography>
        Our Service may contain links to websites and other online services operated by third parties. In addition, our content may be integrated into web pages or other online services that are not associated with us. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. We do not control websites or online services operated by third parties, and we are not responsible for their actions. This Privacy Policy does not apply to such third-party sites or services.
        </BodyTypography>
        <TitleTypography mt={5}>
          Use of the Service by Minors
        </TitleTypography>
        <BodyTypography>
          Our Service is not intended for individuals under the age of 18. If
          you have reason to believe that a minor under the age of 18 has
          provided personal information to us through the Service, please
          contact us and we will endeavor to delete that information from our
          databases.
        </BodyTypography>
        <TitleTypography mt={5}>
          Changes to This Privacy Policy
        </TitleTypography>
        <BodyTypography>
          We reserve the right to modify this Privacy Policy at any time and
          from time to time without prior notice. We encourage you to
          periodically review this page for the latest information on our
          privacy practices. If we make material changes to this Privacy Policy,
          we will notify you by updating the date of this Privacy Policy and
          posting it on the Service or as otherwise required by law.
        </BodyTypography>
        <TitleTypography mt={5}>How to Contact Us</TitleTypography>
        <BodyTypography>
        If you have any questions about this Policy or our privacy practices, or to submit a personal information request, please contact us by email at hello@itini.co
        </BodyTypography>
        <BodyTypography fontWeight="bold">Company</BodyTypography>
        <BodyTypography>● About</BodyTypography>
        <BodyTypography>● Team</BodyTypography>
        <BodyTypography>● Press</BodyTypography>
        <BodyTypography>● Media Kit</BodyTypography>
        <BodyTypography>● Contact</BodyTypography>
        <BodyTypography>● Help</BodyTypography>
        <BodyTypography fontWeight="bold">Creator Program</BodyTypography>
        <BodyTypography>Become a creator</BodyTypography>
        <BodyTypography>Take our travel quiz</BodyTypography>
      </Container>
      <FooterComponent />
    </>
  );
};

export default PrivacyPolicy;
