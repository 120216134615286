import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/images/logo-brown.png";
import { useNavigate } from "react-router-dom";

const ItiniHuntAppBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navItems = [
    { label: "Pain Point", action: () => handleScroll("pain-point") },
    { label: "Our Solution", action: () => handleScroll("solution") },
    { label: "Benefits", action: () => handleScroll("benefits") },
    { label: "Success Stats", action: () => handleScroll("stats") },
  ];

  const drawer = (
    <Box
      sx={{ width: 250 }}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      onClick={handleDrawerToggle}
    >
      <List>
        {navItems.map((item, index) => (
          <ListItem button key={index} onClick={item.action}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
      <Button
        variant='contained'
        sx={{
          bgcolor: "#02434A",
          color: "#ffffff",
          fontWeight: "bold",
          borderRadius: 1,
          alignSelf: "center",
          width: "auto",
          padding: "8px 16px",
          marginTop: 2,
          "&:hover": {
            backgroundColor: "#93FFE6",
          },
        }}
        href='https://tkwdef6eult.typeform.com/to/eoJBvAUl'
        target='_blank'
        rel='noopener noreferrer'
      >
        Get Started
      </Button>
    </Box>
  );

  return (
    <AppBar position='fixed' sx={{ bgcolor: "#02434A" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Typography
          variant='h6'
          component='div'
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleLogoClick}
        >
          <img
            src={logo}
            alt='Itini logo'
            style={{ height: "30px", marginRight: "10px" }}
          />
        </Typography>

        {!isMobile ? (
          <>
            <Box sx={{ display: "flex", gap: 2 }}>
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  sx={{ color: "#fff", fontWeight: "bold" }}
                  onClick={
                    item.action ? item.action : () => navigate(item.path)
                  }
                >
                  {item.label}
                </Button>
              ))}
            </Box>

            <Button
              variant='contained'
              sx={{
                bgcolor: "#C4FFF1",
                color: "#000000",
                fontWeight: "bold",
                borderRadius: 5,
                "&:hover": {
                  backgroundColor: "#93FFE6",
                },
              }}
              href='https://tkwdef6eult.typeform.com/to/eoJBvAUl'
              target='_blank'
              rel='noopener noreferrer'
            >
              Get Started
            </Button>
          </>
        ) : (
          <>
            <IconButton color='inherit' onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor='right'
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ItiniHuntAppBar;
