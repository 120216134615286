import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../../assets/images/logo-brown.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = styled(Box)(({ theme, backgroundColor, color }) => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: backgroundColor
    ? backgroundColor
    : theme.palette.primary.main,
  color: color ? color : "#E0FFF8",
  padding: theme.spacing(2, 4),
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(2),
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

const FooterLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const SocialMediaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const FooterComponent = ({ backgroundColor, color }) => (
  <Footer backgroundColor={backgroundColor} color={color}>
    <Link href='/'>
      <LogoContainer>
        <img src={logo} alt='Itini Logo' style={{ height: 70 }} />
      </LogoContainer>
    </Link>
    <Box>
      <Typography variant='body2'>&copy; 2024 Itini Inc.</Typography>
      <FooterLinks>
        <Link href='/terms-of-service' color='inherit' underline='none'>
          Terms of Service
        </Link>
        <Link href='/privacy-policy' color='inherit' underline='none'>
          Privacy Policy
        </Link>
      </FooterLinks>
    </Box>
    <LinksContainer>
      <Link href='/about-us' color='inherit' underline='none'>
        About
      </Link>
      <Link href='/about-us#press' color='inherit' underline='none'>
        Press
      </Link>
      <Link href='/partnership' color='inherit' underline='none'>
        Partnership
      </Link>
      {/* <Link href='' color='inherit' underline='none'>
        Connect
      </Link> */}
      <Link href='/contact-us' color='inherit' underline='none'>
        Contact Us
      </Link>
      <SocialMediaContainer>
        <IconButton
          href='https://www.facebook.com/profile.php?id=61562320744381'
          target='_blank'
          color='inherit'
        >
          <FacebookIcon />
        </IconButton>
        <IconButton href='http://x.com/itiniai' target='_blank' color='inherit'>
          <XIcon />
        </IconButton>
        <IconButton
          href='http://instagram.com/itini.ai'
          target='_blank'
          color='inherit'
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          href='https://www.linkedin.com/company/itinia-ai'
          target='_blank'
          color='inherit'
        >
          <LinkedInIcon />
        </IconButton>
      </SocialMediaContainer>
    </LinksContainer>
  </Footer>
);

export default FooterComponent;
