import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import yourJourneyImage from "../assets/images/sky-from-plane.jpg";
import beach from "../assets/images/beach.jpg";
import manWomanBeach from "../assets/images/man-woman-beach.jpg";
import hiking from "../assets/images/hiking.jpg";
import friends from "../assets/images/friends.jpg";

const IMAGE_CHANGE_INTERVAL_MS = 3000;

export default function Hero() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const images = [
    { src: beach, alt: "beach" },
    { src: yourJourneyImage, alt: "plane wings" },
    { src: manWomanBeach, alt: "man woman beach" },
    { src: hiking, alt: "hiking" },
    { src: friends, alt: "friends" },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, IMAGE_CHANGE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: isSmallScreen ? "60vh" : "30vh",
        minHeight: "35rem",
        overflow: "hidden",
        mt: isSmallScreen ? 10 : 25,
        backgroundImage: `url(${images[currentImageIndex].src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#02434A",
        transition: "background-image 1s ease-in-out",
      }}
    >
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: [0, 25, 0] }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "mirror",
          ease: "easeInOut",
        }}
      >
        <Typography
          sx={{
            fontSize: isSmallScreen
              ? "2rem"
              : isMediumScreen
              ? "2.5rem"
              : "3rem",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
          }}
        >
          Your AI Experience Planner...
        </Typography>
      </motion.div>
    </Box>
  );
}
