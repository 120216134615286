import React, { useEffect } from "react";
import { Box, Typography, Button, Link, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import AppBarComponent from "../components/StaticPages/AppBarComponent";
import FooterComponent from "../components/StaticPages/FooterComponent";
import itiniWhoImage from "../assets/images/itini-who.png";

const Container = styled(Box)(({ theme }) => ({
  padding: "5% 2%",
  maxWidth: "70%",
  marginTop: "15%",
  marginLeft: "5%",
  marginRight: "5%",
  textAlign: "left",
  "@media (max-width:1200px)": {
    maxWidth: "95%",
    marginTop: "45%",
    padding: "5% 1%",
  },
}));

const AbsoluteImage = styled("img")(({ customStyles, theme }) => ({
  position: "absolute",
  ...customStyles,
  "@media (max-width:1200px)": {
    position: "relative",
    transform: "translateX(0) scale(0.5)",
    marginTop: theme.spacing(4),
    left: "0",
    top: "0",
  },
  "@media (max-width:1400px)": {
    transform: "scale(0.8)",
    right: "-45%",
    top: "-5%",
  },
  // "@media (max-width:1200px)": {
  //   transform: "scale(0.5)",
  //   right: "-55%",
  //   top: "-5%",
  // },
}));

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(6),
  position: "relative",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 50,
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  paragraph: true,
  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
}));

const AboutUsPage = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <AppBarComponent title='Know Us' />
      <Container>
        <Section>
          <Box width='70%'>
            <TitleTypography gutterBottom>Itini who?</TitleTypography>
            <BodyTypography>
              Started in 2023, Itini set out on the journey to reduce decision
              paralysis from having so many options on where to go, what to do,
              and where to eat. We wanted the plans to come out of the group
              chat!
            </BodyTypography>
            <BodyTypography>
              Using our AI to simplify trip details, Itini personalizes
              suggestions to make desirable memories for you and others.
            </BodyTypography>
            <BodyTypography>
              Whether you use us for your layover or for a family weekend, we
              are here to improve your exploration confidence.
            </BodyTypography>
            {!isSmallScreen && (
              <AbsoluteImage
                src={itiniWhoImage}
                alt='foods you like'
                customStyles={{
                  right: "-75%",
                  top: "-5%",
                  transform: "translateX(-50%) scale(0.9)",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              mt: 15,
              ml: 40,
              width: "70%",
              textAlign: "center",
              "@media (max-width:600px)": {
                mt: 8,
                ml: 0,
                width: "100%",
              },
            }}
          >
            <BodyTypography sx={{ mb: 3 }}>
              With over 10,000 itineraries generated, we thrive off of
              understanding the customer, their habits, and their desires.
              Through you we are better. Leave feedback! We’d love to hear from
              you!
            </BodyTypography>
            <Button
              sx={{
                width: "30%",
                backgroundColor: "#02434A",
                color: "#FFFFFF",
                fontWeight: "bold",
                padding: "10px 5px",
                "&:hover": {
                  backgroundColor: "#023a3f",
                },
                "@media (max-width:600px)": {
                  width: "60%",
                },
              }}
              href='/contact-us'
            >
              Contact Us
            </Button>
          </Box>
        </Section>
        <Section id='press'>
          <TitleTypography gutterBottom>Press</TitleTypography>
          <BodyTypography>
            For press inquiries please contact us at pr@itini.co
          </BodyTypography>
          <BodyTypography>
            To download our media kit{" "}
            <Link href='#' underline='always'>
              click here.
            </Link>
          </BodyTypography>
        </Section>
      </Container>
      <FooterComponent />
    </>
  );
};

export default AboutUsPage;
